import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import differenceInDays from 'date-fns/difference_in_days';
// import distance from 'date-fns/distance_in_words_to_now';
import { rgba } from 'polished';
import { view } from 'react-easy-state';
import TimeAgo from 'react-timeago';

import { colors } from '../../tailwind';
import { sortRepos } from '../helpers';
import main from '../stores';

const List = styled.li`
  width: calc(100% + 2rem);
  margin: 0 -1rem;
  padding: 0 1rem;
  font-weight: ${p => (!p.isChild ? 800 : 600)};
  font-size: ${p => (!p.isChild ? 1 : 0.95)}rem;
  /* letter-spacing: ${p => (!p.isChild ? -0.1 : -0.1)}em; */
  transition: all .1s;

  ${p => !p.isChild && css`
    border-bottom: 1px solid ${colors['grey-lighter']};

    &:last-of-type {
      border-bottom: 0;
    }
  `};

  &:hover {
    background: ${rgba(colors.grey, 0.15)};
  }
`;

const ListWrapper = styled.div`
  padding: ${p => (!p.isChild ? 0.75 : 0.25)}rem 0;
`;

const Summary = styled.p`
  ${tw`text-grey-dark font-normal text-xs tracking-tight inline-block`}
`;

const Child = styled.ul`
  list-style: none;
  padding-left: 0;

  > li {
    margin: 0 -1rem;
    padding-left: 2rem;
  }
`;

export const Freshness = ({ pushedAt, style }) => {
  const days = differenceInDays(Date.now(), pushedAt);

  if (days > 15 && days <= 365) return null;

  let color = colors.grey;
  if (days <= 15) color = colors.success;

  return (
    <span
      css={[tw`text-grey rounded uppercase font-bold inline-block opacity-75`, {
        padding: '0.05em 0.2em',
        fontSize: '0.8em',
        border: '1px dotted',
        lineHeight: 1.2,
        color,
      }, style]}
    >
      {days <= 15 && 'Fresh'}
      {days > 365 && 'Outdated'}
    </span>
  );
};

export default
@view
class Repo extends React.Component {
  render() {
    const {
      title,
      slug,
      summary,
      child,
      parentRepo,
      updatedAt,
      pushedAt,
      stars,
      watchers,
      notFound,
      isChild,
      showChild = true,
      showParent = false,
    } = this.props;

    const { sortField, showSummary, showInfo } = main;

    if (notFound) {
      return null;
    }

    const sortedChild = sortRepos({ repos: child, sortField });

    return (
      <List
        key={slug}
        isChild={isChild}
      >
        <ListWrapper isChild={isChild}>
          <div css={tw`flex flex-wrap justify-between items-center`}>
            <div>
              {showParent && parentRepo && (
                <>
                  <Link
                    to={`/${parentRepo.slug}`}
                    css={tw`text-grey-darkest font-medium inline-block hover:no-underline`}
                  >
                    {parentRepo.title}
                  </Link>{' '}
                  <i className="far fa-chevron-right" css={tw`opacity-50 text-xs`} />{' '}
                </>
              )}

              <Link
                to={`/${slug}`}
                css={tw`text-grey-darkest inline-block mr-1 hover:no-underline`}
              >
                {title}
              </Link>
            </div>

            {showInfo && (
              <span css={tw`font-light text-xs tracking-tight text-grey whitespace-no-wrap inline-flex items-center`}>
                <span css={[tw`flex items-center`]}>
                  <TimeAgo date={pushedAt} formatter={(value, unit) => `${value} ${unit}${value > 1 ? 's' : ''}`} />
                  <span css={tw`mx-1`}><Freshness pushedAt={pushedAt} /></span>
                  &middot;
                  <span css={tw`mx-1`}><i className="far fa-star" /> {stars}</span>
                  {/* &middot;
                  <span css={tw`ml-1`}>{watchers}</span> */}
                </span>
              </span>
            )}
          </div>

          {summary && showSummary && (
            <Summary>
              {summary}
            </Summary>
          )}

          {showChild && child && (
            <Child>
              {sortedChild.map(repo => (
                <Repo key={repo.slug} {...repo} isChild />
              ))}
            </Child>
          )}
        </ListWrapper>
      </List>
    );
  }
}
