import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { view } from 'react-easy-state';
import styled from '@emotion/styled';

import { colors } from '../../tailwind';

const Chevron = () => (
  <i className="far fa-angle-right" css={[tw`text-grey mx-2 inline-block`, { fontSize: '0.8em' }]} />
);

const Title = styled.div`
  ${tw`font-medium text-grey-darker text-xl tracking-tight`}
`;

const Menu = styled.ul`
  ${tw`list-reset absolute font-medium text-sm text-grey-dark bg-grey-lighter p-3 shadow rounded flex flex-wrap`}
  width: 24rem;
  top: calc(100%);
  left: -1rem;
  z-index: 10000;
`;

const Item = styled.li`
  ${tw`inline-block p-1`}
`;

const Crumb = styled.div`
  ${tw`relative inline-block`}

  > div {
    cursor: default;
  }

  ${Menu} {
    display: none;
  }

  &:hover ${Menu} {
    display: flex;
  }
`;

const MenuBox = ({ items }) => (
  <Menu>
    {items.map(repo => (
      <Item key={repo.slug}>
        <Link
          to={`/${repo.slug}`}
          activeStyle={{ color: colors.brand, cursor: 'default', textDecoration: 'none' }}
        >
          {repo.title}
        </Link>
      </Item>
    ))}
  </Menu>
);

export default
class BreadcrumbMenu extends React.Component {
  render() {
    const {
      category,
      parentRepo,
    } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query breadcrumbQuery {
            allAweCategory {
              edges {
                node {
                  slug
                  repos {
                    slug
                    title
                    child {
                      slug
                      title
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const contents = data.allAweCategory.edges.map(edge => edge.node);
          const theCategory = contents.find(content => content.slug === category.slug);

          let theParent = null;
          if (parentRepo) {
            theParent = theCategory.repos.find(repo => repo.slug === parentRepo.slug);
          }

          return (
            <div css={tw`mt-4`}>
              <Crumb>
                <Title>{category.title}</Title>
                <MenuBox items={theCategory.repos} />
              </Crumb>

              {parentRepo && (
                <Crumb><Title><Chevron /></Title></Crumb>
              )}

              {parentRepo && theParent && (
                <Crumb>
                  <Title><Link to={`/${parentRepo.slug}`}>{parentRepo.title}</Link></Title>
                  <MenuBox items={theParent.child} />
                </Crumb>
              )}
            </div>
          );
        }}
      />
    );
  }
}
