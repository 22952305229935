import React from 'react';
import { Link, graphql } from 'gatsby';
import isRelativeUrl from 'is-relative-url';
import convert from 'htmr';
import _ from 'lodash';
import styled from '@emotion/styled';
import { rgba } from 'polished';
// import Waypoint from 'react-waypoint';

import Layout from '../components/layout';
import ReadmeHeader from '../components/readme-header';
import AnchorLink from '../components/anchor-link';

import { colors } from '../../tailwind';

import '../styles/github-markdown.css';

const getRepoId = repoUrl => {
  repoUrl = repoUrl.trim().replace(/^https:\/\/github.com\/|\/?(#readme)?$/g, '');
  const parts = repoUrl.split('/');
  parts.length = 2;
  return parts.join('/');
};

const AweLink = styled(Link)`
  &:after {
    content: "Awe";
    display: inline-block;
    border: 1px solid;
    padding: 0 0.15em;
    margin-left: 0.25em;
    border-radius: 3px;
    line-height: 1.25;
    font-size: 0.7em;
    font-weight: 900;
    letter-spacing: -0.05em;
    text-transform: uppercase;
  }

  &:hover {
    text-decoration: none !important;
    color: ${colors['brand-darkest']} !important;
  }
`;

const MyLink = ({
  getSlugByRepoId, isAwesomeList, repoId, href, host, pathname, ...props
}) => {
  // few cases of links:

  // 1) No fref!!
  if (!href) {
    return <a {...props} /> // eslint-disable-line
  }

  // 2) is an anchor (hash links)
  if (props.className === 'anchor' && props.id) {
    props.id = props.id.replace('user-content-', '');
  }
  if (/^#/.test(href)) {
    return <AnchorLink href={href} {...props} />; // eslint-disable-line
  }

  // 3) Linking to sindresorhus/awesome github page, just go back to home
  if (href && (/sindresorhus\/awesome(#readme)?$/.test(href) || /awesome\.re\/?$/.test(href))) {
    return <Link to="/" {...props} />;
  }

  // 4) other links to md files inside the repository
  const match = href.match(new RegExp(`(${repoId}/blob/master/)*?(.+?.(md|markdown))$`));
  if (match) {
    return (
      <a // eslint-disable-line
        {...props}
        href={`https://github.com/${repoId}/blob/master/${match[2]}`}
        target="_blank"
        rel="noopener noreferrer nofollow"
      />
    );
  }

  // 5) other awesome links
  if (/github\.com/.test(href) && !href.includes(repoId)) {
    const otherRepoId = getRepoId(href);
    const slug = getSlugByRepoId({ repoId: otherRepoId });
    if (slug) {
      const { children, ..._props } = props;
      return (
        <AweLink to={slug} {..._props}>
          {children}
        </AweLink>
      );
    }
  }

  // 6) external links
  if (!isRelativeUrl(href) && !(new RegExp(`^${host}`)).test(href)) {
    return (
      <a // eslint-disable-line
        {...props}
        href={href}
        target="_blank"
        rel="noopener noreferrer nofollow"
      />
    );
  }

  return <a {...props} href={href} /> // eslint-disable-line
};

const MyImg = ({
  repoId, defaultBranch, src, ...props
}) => {
  src = src.replace(/https:\/\/(www\.)?github\.com/, 'https://raw.githubusercontent.com');
  src = src.replace(/(raw|blob)\//, '');

  if (isRelativeUrl(src)) {
    src = src.replace(/^\/+/, '');
    const sanitize = /\.svg$/.test(src) ? '?sanitize=true' : '';
    src = `https://raw.githubusercontent.com/${repoId}/${defaultBranch}/${src}${sanitize}`;
  }

  src = src.replace(/^http:/, 'https:/');

  return <img {...props} src={src} /> // eslint-disable-line
};

// const WH1 = ({ setSection, ...props }) => <Waypoint onEnter={}><h1 {...props} /></Waypoint>;

export default class Readme extends React.Component {
  // state = {
  //   currentSection: null
  // }

  getSlugByRepoId = ({ repoId }) => {
    const repo = _.find(this.props.data.allAweRepo.edges, edge => edge.node.repoId === repoId);

    if (repo) {
      return repo.node.slug;
    }

    return null;
  }

  isAwesomeList = ({ repoId }) => {
    const repo = _.find(this.props.data.allAweRepo.edges, edge => edge.node.repoId === repoId);

    if (repo) {
      return true;
    }

    return false;
  }

  render() {
    const {
      data: { aweRepo: info, markdownRemark: markdown },
      location,
    } = this.props;

    if (!markdown) {
      console.log(info.repoId);
      return null;
    }

    const title = [];
    if (markdown.frontmatter) {
      if (markdown.frontmatter.parentTitle) {
        title.push(markdown.frontmatter.parentTitle);
      }
      title.push(markdown.frontmatter.title);
    }

    const transformReadme = {
      a: props => (
        <MyLink
          {...props}
          repoId={info.repoId}
          getSlugByRepoId={this.getSlugByRepoId}
          isAwesomeList={this.isAwesomeList}
          host={location.host}
          pathname={location.pathname}
        />
      ),
      img: props => (
        <MyImg
          {...props}
          repoId={info.repoId}
          defaultBranch={markdown.frontmatter.defaultBranch}
        />
      ),
      article: ({ itemprop, ...props }) => <article {...props} itemProp={itemprop} />,
    };
    const processedReadme = convert(markdown.html, { transform: transformReadme });

    const transformTOC = {
      a: props => (
        <AnchorLink {...props} />
      ),
    };
    const processedTOC =
      markdown.fields.toc ? convert(markdown.fields.toc, { transform: transformTOC }) : null;

    return (
      <Layout
        title={title.length > 0 && title.join(': ')}
        pageHeader={<ReadmeHeader info={info} markdown={markdown} />}
      >
        <div css={tw`flex flex-row-reverse flex-wrap`}>
          <div css={tw`hidden md:block w-1/5 px-4 flex-1 relative`}>
            {processedTOC && (
              <div css={tw`sticky pin-t py-4 max-h-screen overflow-y-scroll`}>
                <h3
                  css={[
                    tw`font-bold text-brand-darker text-lg mb-4 pb-1 relative`, {
                      '&:after': {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        bottom: -4,
                        width: '1.5rem',
                        height: 4,
                        background: colors.brand,
                        borderRadius: 4,
                      }
                    }
                  ]}
                >
                  Contents
                </h3>

                <div id="toc">{processedTOC}</div>
              </div>
            )}
          </div>

          <div
            css={[
              tw`w-full md:w-4/5 p-8 rounded-lg overflow-hidden bg-white border-grey-lighter border-0 z-10`,
              {
                boxShadow: `0 0 2px 0 ${rgba('#000', 0.1)}, 0 5px 15px 0 ${rgba('#000', 0.0325)}`,
              }
            ]}
            id="main-content"
            className="markdown-body"
            ref={el => { this.readme$ = el; }}
          >
            {processedReadme}
          </div>
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    aweRepo(slug: { eq: $slug }) {
      url
      repoId
      slug
      category {
        slug
        title
      }
      parentRepo {
        slug
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      rawMarkdownBody
      html
      fields {
        toc
      }
      frontmatter {
        path
        owner
        ownerId
        ownerAvatar
        title
        parentTitle
        categoryTitle
        description
        homepage
        updatedAt
        pushedAt
        stars
        watchers
        forks
        defaultBranch
        topics
      }
    }
    allAweRepo {
      edges {
        node {
          slug
          repoId
        }
      }
    }
  }
`;
