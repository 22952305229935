import React from 'react';
// import Emoji from 'react-emoji-render';
import TimeAgo from 'react-timeago';
import emojis from 'emojis';

import BreadcrumbMenu from './breadcrumb-menu';
import { Freshness } from './repo';

const InfoBar = ({ info, frontmatter }) => {
  const [owner, name] = info.repoId.split('/');

  return (
    <h4 css={tw`text-grey-dark text-sm font-medium tracking-wide mt-3 flex items-center flex-wrap`}>
      <span css={tw`flex flex-no-wrap items-center`}>
        <a
          href={`https://github.com/${owner}`}
          target="_blank"
          rel="noopener noreferrer nofollow"
          css={tw`text-brand flex items-center`}
        >
          <img
            css={tw`block h-6 rounded-full mr-2`}
            src={`${frontmatter.ownerAvatar}&s=64`}
            alt={frontmatter.owner}
          />
          {owner}
        </a>
        <span css={tw`mx-1`}>/</span>
        <a
          href={`https://github.com/${owner}/${name}`}
          target="_blank"
          rel="noopener noreferrer nofollow"
          css={tw`text-brand`}
        >
          {name}
        </a>
      </span>

      <span css={tw`text-grey-dark font-normal tracking-tight`}>
        <a
          href={`https://github.com/${info.repoId}/commits`}
          target="_blank"
          rel="noopener noreferrer nofollow"
          css={tw`ml-4`}
        >
          Updated <TimeAgo date={frontmatter.pushedAt} />
        </a>
        <Freshness pushedAt={frontmatter.pushedAt} style={tw`ml-1`} />
        <span css={tw`ml-4`}><i className="far fa-star" /> {frontmatter.stars}</span>
        <span css={tw`ml-4`}><i className="far fa-eye" /> {frontmatter.watchers}</span>
        <span css={tw`ml-4`}><i className="far fa-code-branch" /> {frontmatter.forks}</span>
      </span>
    </h4>
  );
};

export default
class ReadmeHeader extends React.Component {
  render() {
    const { info, markdown: { frontmatter } } = this.props;

    return (
      <div css={tw`pt-8 max-w-xl`}>
        <BreadcrumbMenu
          category={info.category}
          parentRepo={info.parentRepo}
        />

        <h2 css={[tw`font-hairline text-grey-darker text-5xl tracking-normal leading-none`, { marginTop: -4 }]}>
          {frontmatter.title}
        </h2>

        {frontmatter.description && (
          <h4 css={tw`text-grey-dark text-2xl font-thin mt-1`}>
            <span css={tw`mr-4`}>{emojis.unicode(frontmatter.description)}</span>
            {frontmatter.homepage && (
              <a
                href={frontmatter.homepage}
                css={tw`inline-block text-brand hover:no-underline hover:text-black`}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                {frontmatter.homepage.replace(/^https?:\/\//, '').replace(/\/$/, '')}
              </a>
            )}
          </h4>
        )}

        {frontmatter.topics && (
          <div css={tw`mt-4 flex flex-wrap`}>
            {frontmatter.topics.map(topic => (
              <a
                key={topic}
                css={[
                  tw`block mb-1 mr-1 px-2 py-1 bg-brand-lightest text-brand-darker font-bold rounded hover:bg-brand hover:text-white hover:no-underline uppercase`,
                  {
                    fontSize: '0.65rem'
                  }
                ]}
                href={`https://github.com/topics/${topic}`}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                {topic}
              </a>
            ))}
          </div>
        )}

        <InfoBar info={info} frontmatter={frontmatter} />
      </div>
    );
  }
}
