import _ from 'lodash';

const scrollToCoord = (top) => {
  window.scroll({
    top,
    behavior: 'auto'
  });
};

const scrollToHref = (href) => {
  try {
    // destination element to scroll to
    const destinationElement = document.querySelector(href);

    if (destinationElement) {
      scrollToCoord(destinationElement.offsetTop);
    }
  } catch (e) {}
};

const scroll = {
  toCoord: scrollToCoord,
  toHref: scrollToHref,
};

const sortRepos = ({ repos, sortField }) => _.orderBy(repos, [sortField.field], [sortField.order]);

export { scroll, sortRepos };
